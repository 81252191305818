$(document).ready(function () {

    $('.menu__close, .header__toggle_container, .overflow').on('click', toggleMenu);

    $('.footer__toggle').on('click', function (e) {
        $('.footer__menu').addClass('footer__menu_opened');
        e.preventDefault();
    });

    var inputPassportCode = $('.input_passport-code');
    var inputPassportIssued = $('.input_passport-issued');

    $('.input_tel').mask('+7 000 000 00 00');
    $('.input_date').mask('00.00.0000');
    $('.input_passport-series').mask('00 00');
    $('.input_passport-number').mask('000000');
    $('.input_snils').mask('000-000-000 00');
    inputPassportCode.mask('000-000');

    $('.calendar').datepicker();

    var fileInput = $('.file__input');

    fileInput.change(function () {

        var uploadingContainer = $(this).parents('.questionnaire__uploading');
        var chargedContainer = uploadingContainer.find('.charged');
        var uploadContainer = uploadingContainer.find('.upload');

        uploadContainer.find('.questionnaire__tip').removeClass('questionnaire__tip_highlight');

        if ($(this).val()) {
            var fileInputType = ($(this).get(0).files[0].type === 'image/jpg' || $(this).get(0).files[0].type === 'image/jpeg') ? 'image' : $(this).get(0).files[0].type === 'application/pdf' ? 'pdf' : false;

            if (fileInputType) {
                chargedContainer.find('.charged__preview')
                    .removeClass('charged__preview_image')
                    .removeClass('charged__preview_pdf')
                    .addClass('charged__preview_' + fileInputType);

                var _size = $(this).get(0).files[0].size;
                var fSExt = ['Байт', 'Кб', 'Мб', 'Гб'],
                    i=0;while(_size>900){_size/=1024;i++;}
                var exactSize = (Math.round(_size))+' '+fSExt[i];

                chargedContainer.find('.charged__size').html(exactSize);
                chargedContainer.find('.charged__name').html($(this).get(0).files[0].name);

                chargedContainer.slideDown();
                uploadContainer.slideUp();
            } else {
                $(this).val(null);
                uploadContainer.find('.questionnaire__tip').addClass('questionnaire__tip_highlight');
            }
        } else {
            chargedContainer.slideUp();
            uploadContainer.slideDown();
        }

    });

    $(document).on('click', '.charged__remove', function () {
        $(this).parents('.questionnaire__uploading').find('.file__input').val(null).trigger('change');
    });

    var buttonGetPrize = $('.check__button_get');
    var inputAccept = $('.checkbox__input_accept');

    inputAccept.change(function () {
        if ($(this).is(':checked')) {
            buttonGetPrize.removeAttr('disabled');
        } else {
            buttonGetPrize.prop('disabled', true);
        }
    });
    var token = "a83aae930a3b6c7f8abd65214f4b0ccf5d472be8";

    function formatResult(value, currentValue, suggestion) {
        suggestion.value = suggestion.data.code;
        return suggestion.data.code + " — " + suggestion.data.name;
    }

    function showSuggestion(suggestion) {
        inputPassportIssued.val(suggestion.data.name);
    }

    function clearSuggestion() {
        inputPassportIssued.val("");
    }

    inputPassportCode.suggestions({
        token: token,
        type: "fms_unit",
        formatResult: formatResult,
        onSelect: showSuggestion,
        onSelectNothing: clearSuggestion
    });

});

function toggleMenu(event) {
    event.preventDefault();

    var headerToggle = $('.header__toggle');
    const targetElement = $('.menu');

    if (headerToggle.hasClass('active')) {
        $('.overflow').removeClass('overflow_show');
        $('.menu').removeClass('menu_opened');
        $('.station').removeClass('station_hidden');
        headerToggle.removeClass('active');
        $('.header__wrapper').removeClass('header__wrapper_opened');
        bodyScrollLock.enableBodyScroll(targetElement);
        bodyScrollLock.clearAllBodyScrollLocks();
    } else {
        $('.menu').addClass('menu_opened');
        $('.overflow').addClass('overflow_show');
        $('.station').addClass('station_hidden');
        headerToggle.addClass('active');
        $('.header__wrapper').addClass('header__wrapper_opened');
        bodyScrollLock.disableBodyScroll(targetElement);
    }
}
